import { navigate } from 'gatsby-link';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { images } from '../../data';
import { getFilterData, IngredientList } from '../../utils';
import { IngredientIconHeader } from './IngredientIconHeader';

import { ToxicityItem } from './ToxicityItem';

const IngredientCautionTemplate = ({
  productData,
  onShowInterestIngredient,
  goIngredientDetailPage,
  blurComponent,
  ingredientGrade,
}: any) => {
  const appType = useSelector((state) => state.appType);
  const [ingredientIconShowName, setIngredientIconShowName] = useState<string>();
  const productIngredients = productData.ingredients;
  const groupedIngredients = productData.groupedIngredients;

  const echaBreath = IngredientList(productIngredients, 'echaBreath', undefined, '#898989');
  const echaSkin = IngredientList(productIngredients, 'echaSkin', 'allergic', '#898989');
  const echSkinDatas = groupedIngredients.byUsage.allergic.ings;
  const echaBreathDatas = groupedIngredients.byUsage.breath.ings;

  const onPressIngredientItem = (where, ingredient) => {
    //   amplitudeLog(`제품 상세 - ${title} - 성분 클릭`, logData);
    goIngredientDetailPage(where, ingredient);
  };

  const setIsShow = (type: string) => {
    if (type === ingredientIconShowName) {
      setIngredientIconShowName(undefined);
    } else {
      // amplitudeLog('제품 상세 - 영유아/임산부 주의성분 - 아이콘 클릭', {
      //   제품명: productData.name,
      //   아이콘명: type,
      //   productId: productData.id,
      // });
      setIngredientIconShowName(type);
    }
  };

  const highDatas = groupedIngredients.byCaution.high.ings;
  const middleDatas = groupedIngredients.byCaution.middle.ings;
  const lowDatas = groupedIngredients.byCaution.low.ings;
  const highIcon = {
    source: highDatas?.length === 0 ? images.nocaution : images.highcaution,
    size: 22,
  };
  const middleIcon = {
    source: middleDatas?.length === 0 ? images.nocaution : images.moderatecaution,
    size: 22,
  };
  const lowIcon = {
    source: lowDatas?.length === 0 ? images.nocaution : images.lowcaution,
    size: 22,
  };

  const sectionData: any[] = [];

  sectionData.push({
    header: {
      title: appType === 'pet' ? '민감한 반려동물 주의성분' : '영유아 · 임산부 주의성분',
      headerType: 0,
    },
    data: [
      {
        title: '높은 주의',
        data: highDatas,
        icon: highIcon,
        isBottomLine: true,
        description: undefined,
      },
      {
        title: '중간 주의',
        data: middleDatas,
        icon: middleIcon,
        isBottomLine: true,
        description: undefined,
      },
      {
        title: '낮은 주의',
        data: lowDatas,
        icon: lowIcon,
        isBottomLine: false,
        description: undefined,
      },
    ],
  });
  sectionData.push({
    header: { title: '사용대상/사용방법에 따른 체크성분', headerType: 1 },
    data: [
      {
        title: '알레르기 주의 성분',
        data: echSkinDatas,
        icon: echaSkin,
        isBottomLine: true,
        description: '개개인에 따라 다르게 나타나므로 테스트 후 이상이 없다면 안전합니다.',
      },
      {
        title: '흡입 주의 성분',
        data: echaBreathDatas,
        icon: echaBreath,
        isBottomLine: false,
        description: '흡입 가능성이 없는 제형의 제품이라면, 크게 우려하지 않으셔도 됩니다.',
      },
      // {
      //   title: '발달 · 생식 독성 성분',
      //   data: echaGrowthDatas,
      //   icon: echaGrowth,
      //   isBottomLine: false,
      //   description: '노출량, 노출방식에 따라 다르게 나타나므로 잘못된 사용방법으로\n 장기간 사용하는 것은 주의해주세요.',
      // },
    ],
  });

  const sectionHeader = ({ section: { header, data } }) => {
    const headerType = header.headerType;

    return (
      <div
        style={{
          backgroundColor: '#ffffff',
          borderTop: 'solid #f3f3f3',
          borderTopWidth: headerType === 1 ? 3 : 0,
          borderBottom: 'solid 1px #ffffff',
        }}
      >
        <IngredientIconHeader
          onShowInterestIngredient={onShowInterestIngredient}
          headerType={headerType}
          title={header.title}
          productIngs={productIngredients.map((d) => {
            return {
              ...d,
              categoryType: productData.categoryType,
            };
          })}
          ingredientListData={getFilterData(productIngredients, ingredientIconShowName).map((d) => ({
            ...d,
            categoryType: productData.categoryType,
          }))}
          ingredientIconShowName={ingredientIconShowName}
          setIsShow={setIsShow}
          products={[]}
          onItemClickLog={(where: string, ingredient?: any, logData?: any) => {
            const version = (ingredient.product?.categoryType ?? ingredient.categoryType) === 1 ? 'v1' : 'v2';

            navigate(`/ingredientDetail/${ingredient.id}?version=${version}`, {
              state: {
                ingredient: JSON.stringify(ingredient),
              },
            });
          }}
        />
      </div>
    );
  };

  const cautionGroupComp = (sectionData) => {
    const levelCautionData: any[] = sectionData.data;

    return levelCautionData.map((item, idx) => {
      return (
        <ToxicityItem
          goIngredientDetailPage={onPressIngredientItem}
          icon={item.icon}
          data={item.data}
          product={productData}
          title={item.title}
          textColor={'#424242'}
          description={item?.description}
        />
      );
    });
  };

  if (ingredientGrade === 'X') {
    return (
      <div>
        <div style={{ backgroundColor: 'white', width: '100%', paddingTop: 20 }}>
          {blurComponent()}
          {sectionHeader({ section: sectionData[0] })}
          <div style={{ marginTop: 10.6 }}>{cautionGroupComp(sectionData[0])}</div>

          {sectionHeader({ section: sectionData[1] })}
          <div style={{ marginTop: 10.6, marginBottom: 20 }}>{cautionGroupComp(sectionData[1])}</div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {sectionData.map((section, index) => {
        return (
          <div key={index}>
            {sectionHeader({ section })}
            {section.data.map((item) => {
              return (
                <div key={item.title}>
                  <ToxicityItem
                    goIngredientDetailPage={onPressIngredientItem}
                    icon={item.icon}
                    data={item.data}
                    product={productData}
                    title={item.title}
                    textColor={'#424242'}
                    isBottomLine={item.isBottomLine}
                    description={item.description}
                  />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export { IngredientCautionTemplate };
