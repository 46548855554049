import React from 'react';

const AwardListItem = (props) => {
  const { data, toggleAward, index } = props;

  return (
    <div style={{ flex: 1, marginBottom: 10 }}>
      <div
        style={{ display: 'flex', alignItems: 'center' }}
        onClick={() => {
          toggleAward(index);
        }}
      >
        <span
          style={{
            fontSize: 14,
            fontWeight: 500,
            cursor: 'pointer',
            textDecorationLine: 'underline',
          }}
        >
          {data.title}
        </span>
        {data.title.includes('맘가이드 클린') && (
          <img
            style={{
              marginLeft: 3,
              width: 15,
              height: 15,
              objectFit: 'contain',
              imageRendering: '-webkit-optimize-contrast',
            }}
            src={'/images/main/grade/V_grade2023.png'}
          />
        )}
        {data.title.includes('펫가이드 클린') && (
          <img
            style={{
              marginLeft: 3,
              width: 15,
              height: 15,
              objectFit: 'contain',
              imageRendering: '-webkit-optimize-contrast',
            }}
            src={'/images/main/grade/V_gradepet2023.png'}
          />
        )}
        {data.title.includes('맘가이드 어워드') && (
          <img style={{ marginLeft: 3, width: 15, height: 15 }} src={'/images/main/grade/Award.png'} />
        )}
      </div>
      <div style={{ display: 'flex', marginTop: 5 }}>
        {data.content && (
          <span
            style={{
              fontSize: 12,
              color: '#898989',
              fontWeight: 500,
              marginBottom: 5,
            }}
            onClick={toggleAward}
          >
            {data.content}
          </span>
        )}
      </div>
    </div>
  );
};

export default AwardListItem;
