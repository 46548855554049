import moment from 'moment';
import React, { CSSProperties } from 'react';
import useMomguideProductGrades from '../../hooks/useMomguideGrade';
import * as styles from '../../styles/productDetail/awardExplain.module.css';

const Dot = (props: { text: string; textStyle?: CSSProperties; dotColor?: string; dotSize?: number; style?: CSSProperties }) => {
  const { text, textStyle, dotColor = '#d8d8d8', dotSize = 5, style } = props;
  return (
    <li
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
        marginBottom: 15,
        ...style,
      }}
    >
      <div
        style={{
          marginTop: 5,
          width: 10,
        }}
      >
        <div
          style={{
            width: dotSize,
            height: dotSize,
            borderRadius: dotSize / 2,
            backgroundColor: dotColor,
          }}
        ></div>
      </div>
      <div style={{ flex: 1, marginRight: 22 }}>
        <span className={styles.explainText} style={textStyle}>
          {text}
        </span>
      </div>
    </li>
  );
};

const AwardHistoryComp = (props) => {
  const { product } = props;
  const isCertified = product?.ProductProperty?.isCertified;
  const momguideProductGrades = useMomguideProductGrades();
  const getMomguideAwardPeriod = (startData: string | Date, endData?: string | Date) => {
    const format = 'yyyy년 M월 D일';

    if (endData) return moment(startData).format(format) + ' ~ ' + moment(endData).format(format);
    else return moment(startData).format(format) + ' ~ ';
  };

  const titleAndIconByCleanOrAwardComp = (awardData) => {
    let iconPath = '';

    if (awardData.title.includes('맘가이드 클린')) {
      iconPath = '/images/main/grade/V_grade2023.png';
    } else if (awardData.title.includes('펫가이드 클린')) {
      iconPath = '/images/main/grade/V_gradepet2023.png';
    } else if (awardData.title.includes('맘가이드 어워드')) {
      iconPath = '/images/main/grade/Award.png';
    }

    return iconPath ? (
      <div style={{ display: 'flex' }}>
        <span
          style={{
            color: 'var(--black2)',
            fontSize: 15,
            fontWeight: 700,
          }}
        >
          {awardData.title}
        </span>
        <span>
          <img
            style={{
              marginLeft: 3,
              width: 15,
              height: 15,
              objectFit: 'contain',
              imageRendering: '-webkit-optimize-contrast',
            }}
            src={iconPath}
          />
        </span>
      </div>
    ) : (
      false
    );
  };

  const cleanInfoComp = (awardData) => {
    const cleanTitle = awardData.title;
    let iconPath = '';
    if (cleanTitle.includes('맘가이드 클린')) {
    }
  };

  const awardInfoComp = () => {};

  return (
    <div
      style={{
        display: 'flex',
        background: '#ffffff',
        flexDirection: 'column',
        margin: 0,
        width: '100%',
      }}
    >
      <div className={styles.item}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img
            style={{
              width: 22,
              height: 22,
              objectFit: 'contain',
              imageRendering: '-webkit-optimize-contrast',
            }}
            src={
              isCertified
                ? '/images/main/grade/A_gradeisCertified.png'
                : momguideProductGrades.find((item: ProductGrade) => item.grade === product?.ProductProperty?.productGrade)?.image2 || null
            }
          />
          {isCertified && (
            <img
              style={{
                width: '30px',
                height: '30px',
                position: 'absolute',
              }}
              src={`/images/main/grade/mom_v_grade_edge.png`}
            />
          )}
        </div>
        <img src={`${product?.imgUrl}?100x100`} className={styles.itemImage} />
        <div
          style={{
            flex: 2,
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center',
          }}
        >
          <span className={styles.itemBrand}>{product?.brand}</span>
          <span className={styles.itemName}>{product?.name}</span>
        </div>
      </div>

      <ul style={{ listStyleType: 'none' }}>
        {product?.momguideAwards.map((awardData, idx) => {
          return (
            <li
              key={idx.toString()}
              style={{
                borderTopWidth: 5,
                borderTopColor: '#f3f3f3',
                borderTopStyle: 'solid',
              }}
            >
              <div style={{ margin: 17 }}>
                {titleAndIconByCleanOrAwardComp(awardData)}

                <div style={{ display: 'flex', marginTop: 5 }}>
                  <span
                    style={{
                      color: 'var(--darkGray)',
                      fontSize: 12,
                    }}
                  >
                    {!awardData.title.includes('맘가이드 어워드') && (
                      <div>유효기간 : {getMomguideAwardPeriod(awardData.awardedAt, awardData.expiredAt)}</div>
                    )}
                  </span>
                  {awardData.title.includes('맘가이드 어워드') && awardData.content && (
                    <span
                      style={{
                        fontSize: 12,
                        color: '#898989',
                        fontWeight: 400,
                      }}
                    >
                      {awardData.content}
                      {/* <span style={{ color: '#0ad3bd', fontSize: 13, fontWeight: 700 }}>
                        {awardData.rank ? ` ${awardData.rank}위` : ''}
                      </span> */}
                    </span>
                  )}
                </div>
              </div>
              {!!awardData.reason && (
                <div
                  style={{
                    borderTopStyle: 'solid',
                    borderTopWidth: 0.5,
                    margin: '0 17px',
                    padding: '10px 0',
                    paddingLeft: 5,
                    borderTopColor: 'var(--lightDarkGray)',
                  }}
                >
                  <span
                    style={{
                      marginBottom: 19,
                      color: 'var(--black2)',
                      fontSize: 12,
                    }}
                  >
                    {'<검토완료>'}
                  </span>
                  <ul className={styles.explainTextUl}>
                    {awardData.reason?.split('\n').map((data) => (
                      <li style={{ marginBottom: 3 }} className={styles.explainText}>
                        {data}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default AwardHistoryComp;
