import { Link, navigate } from 'gatsby';
import React from 'react';
import { useDispatch } from 'react-redux';
import { apiGetBrandCategory } from '../../apis/categoryApi';
import { images } from '../../data';
import { showPopup } from '../../state/rootStore';
import * as styles from '../../styles/productDetail/productInfo.module.css';
import LoadingComp from '../Loading';
import StarRate from '../StarRate';

const RenewalButton = (props: { title: string; productId: number }) => {
  const { title, productId } = props;

  return (
    <div
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        cursor: productId ? 'pointer' : 'default',
      }}
      key={productId}
      onClick={() => {
        navigate(`/productDetail/${productId}`);
      }}
    >
      <span
        style={{
          color: productId ? '#898989' : '#d0d0d0',
          fontSize: 11,
          fontWeight: 400,
        }}
      >
        {title}
      </span>
    </div>
  );
};

export default function ProductInfo(props) {
  const { product } = props;
  const dispatch = useDispatch();

  const discontinuedText = product?.ProductProperty?.isDiscontinued ? '이 제품은 단종된 제품입니다.' : null;
  const discontinuedBackgroundColor = '#898989';

  const issueText = product?.ProductProperty?.issueDescription;
  const issueBackgroundColor = '#EF3C70';

  return (
    <div>
      {discontinuedText && (
        <div
          style={{
            backgroundColor: discontinuedBackgroundColor,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 18,
            marginBottom: issueText ? 0 : 6,
            display: 'flex',
          }}
        >
          <span style={{ color: '#ffffff', fontSize: 11, fontWeight: 300 }}>※{discontinuedText}</span>
        </div>
      )}
      {issueText && (
        <div
          style={{
            backgroundColor: issueBackgroundColor,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 18,
            marginBottom: 6,
            display: 'flex',
          }}
        >
          <span style={{ color: '#ffffff', fontSize: 11, fontWeight: 300 }}>※{issueText}</span>
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.productImageWithNameBox}>
          <div
            style={{
              flex: 1,
              alignItems: 'center',
              display: 'flex',
              position: 'relative',
              justifyContent: 'center',
            }}
          >
            <img
              style={{
                width: 192,
                height: 192,
                objectFit: 'contain',
              }}
              src={product?.imgUrl ? product?.imgUrl + '?production' : undefined}
              alt="제품 이미지"
            />
          </div>
          <div
            style={{
              position: 'absolute',
              right: 5,
              top: 0,
              display: 'flex',
              cursor: 'pointer',
            }}
          >
            <div
              style={{
                alignItems: 'center',
                borderRadius: 5,
                paddingLeft: 6,
                paddingRight: 6,
                paddingTop: 3,
                paddingBottom: 3,
                display: 'flex',
                position: 'relative',
                backgroundColor: '#F7F7F7',
              }}
              onClick={() => {
                dispatch(
                  showPopup({
                    text: `본 제품 정보에 대해 수정 요청을 하시려면 맘가이드앱 1:1문의 혹은\nmomguide@momguide.co.kr로 메일 주시기 바랍니다.`,
                    popupLastPath: '수정 요청',
                  }),
                );
              }}
            >
              <span style={{ fontSize: 11, color: '#898989', fontWeight: 500 }}>수정 요청</span>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ position: 'absolute', left: 10, top: 0 }}>
              <span style={{ color: '#898989', fontSize: 11 }}>{product.additionalCategory}</span>
            </div>
            {product.productBeforeRenewal?.id || product.productAfterRenewal?.id ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    border: 'solid 1px #DADADA',
                    borderRadius: 20,
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '6px 12px 6px 12px',
                  }}
                >
                  <RenewalButton title="〈 리뉴얼 전" productId={product.productBeforeRenewal?.id} />
                  <span style={{ fontSize: 11, color: '#DADADA', padding: '0px 6px 0px 6px' }}>|</span>
                  <RenewalButton title="리뉴얼 후 〉" productId={product.productAfterRenewal?.id} />
                </div>
              </div>
            ) : (
              false
            )}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginTop: 20,
          }}
        >
          <span className={styles.brandText}>{product.brand}</span>
          <span className={styles.productText}>{product.name}</span>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StarRate maxRating={5} gap={5} rating={product.score} size={14} />
            <span className={styles.scoreText}>{product.score}</span>
            <span className={styles.reviewCountText}>{product.scoreCnt}건 리뷰</span>
          </div>
        </div>
      </div>
      <div
        style={{
          borderBottom: 'solid 0.5px #DADADA',
          paddingBottom: 13,
        }}
      />
    </div>
  );
}
