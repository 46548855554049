// extracted by mini-css-extract-plugin
export var arrow = "ingredient-module--arrow--afaa1";
export var authBox = "ingredient-module--authBox--d2a1e";
export var boldText = "ingredient-module--boldText--210c1";
export var button = "ingredient-module--button--7d354";
export var buttonText = "ingredient-module--buttonText--06fa9";
export var container = "ingredient-module--container--cb32c";
export var expalinButtonContainer = "ingredient-module--expalinButtonContainer--b5724";
export var explainText = "ingredient-module--explainText--bb77e";
export var explainTitle = "ingredient-module--explainTitle--e833a";
export var gradeExplainContainer = "ingredient-module--gradeExplainContainer--f0c1e";
export var gradeExplainTextBox = "ingredient-module--gradeExplainTextBox--25f09";
export var heightHideValue = "ingredient-module--heightHideValue--03ad8";
export var heightShowValue = "ingredient-module--heightShowValue--e420a";
export var ingredientRequestButton = "ingredient-module--ingredientRequestButton--521d9";
export var ingredientRequestButtonText = "ingredient-module--ingredientRequestButtonText--763eb";
export var listContainer = "ingredient-module--listContainer--a132f";
export var listContainerFitst = "ingredient-module--listContainerFitst--ec763";
export var listContainerShow = "ingredient-module--listContainerShow--880a4";
export var title = "ingredient-module--title--4e404";