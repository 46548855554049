import React, { useEffect, useState } from 'react';
import * as styles from '../styles/modal/modal.module.css';
import ModalHeader from './ModalHeader';
import { useIsMobile } from './mediaQuery';

const ModalPopup = (props) => {
  const { isVisible, setIsVisible, children, title, backgroundColor = '#ffffff', titleStyle } = props;
  const isMobile = useIsMobile();
  useEffect(() => {
    if (isVisible) document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflowX = 'hidden';
      document.body.style.overflowY = 'scroll';
    };
  }, [isVisible]);

  return (
    <div
      className={isMobile ? styles.mobileModalContainer : styles.pcModalContainer}
      style={{
        height: isVisible ? '100%' : 0,
        backgroundColor: '#ffffff',
      }}
    >
      {title && (
        <ModalHeader
          //   style={{ top: 0 }}
          backgroundColor={backgroundColor}
          title={title}
          titleStyle={titleStyle}
          onCancel={() => {
            setIsVisible(false);
          }}
        />
      )}
      <div style={{ height: isMobile ? 0 : 90 }} />
      {children}
    </div>
  );
};

export default ModalPopup;
