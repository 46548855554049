import React from 'react';
import { images } from '../../data';
import * as styles from '../../styles/productDetail/ingredientList.module.css';
import { Sort } from '../../utils';

const ingredientExplainTextDatas = [
  {
    categoryType: 1,
    ingredientVersion: 'v1',
    explainTexts: [
      { text: '성분은 등급 순서대로 기재되어 있습니다.' },
      {
        text: '본 제품에 표기된 성분명과 화장품 성분 표기법에 따라 표준화된 맘가이드의 성분명이 다를 수 있으니 "성분 이명"을 확인해주세요.',
        screen: 'TipsDetail',
        screenId: 1301,
      },
    ],
  },
  {
    categoryType: 2,
    ingredientVersion: 'v2',
    explainTexts: [{ text: '전성분은 함량 순서대로 기재되어 있습니다.' }],
  },
  {
    categoryType: 3,
    ingredientVersion: 'v2',
    explainTexts: [
      { text: '해당 성분 정보는 함량 순이 아닙니다.' },
      {
        text: '본 제품에 표기된 성분명과 화장품 성분 표기법에 따라 표준화된 맘가이드의 성분명이 다를 수 있으니 "성분 이명"을 확인해주세요.',
        screen: 'TipsDetail',
        screenId: 1301,
      },
    ],
  },
  {
    categoryType: 4,
    ingredientVersion: 'v2',
    explainTexts: [
      { text: '해당 성분 정보는 함량 순이 아닙니다.' },
      {
        text: '본 제품에 표기된 성분명과 화장품 성분 표기법에 따라 표준화된 맘가이드의 성분명이 다를 수 있으니 "성분 이명"을 확인해주세요.',
        screen: 'TipsDetail',
        screenId: 1301,
      },
    ],
  },
];

const Plus = ({ size = 10, borderColor, plusColor }) => {
  return (
    <div
      style={{
        display: 'flex',
        border: `solid 0.5px ${borderColor}`,
        borderRadius: 100,
        borderWidth: 0.5,
        width: size,
        height: size,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          width: size * 0.55,
          height: 0.8,
          backgroundColor: plusColor,
          marginLeft: 1,
        }}
      />
      <div
        style={{
          position: 'absolute',
          width: size * 0.55,
          height: 0.8,
          backgroundColor: plusColor,
          transform: 'rotate(90deg)',
        }}
      />
    </div>
  );
};

const IngredientListItem = (props) => {
  const { product, index, count, goIngredientDetailPage } = props;
  // const { categoryType } = product
  // 기존에는 categoryType === 1이면 모두 성분의 버전이 v1 이였는데
  // categoryType = 4이면서 ingredient version = 'v1'인 케이스가 생김 (펫리빙, categoryId 9~)
  // 따라서 categoryType으로 구분이 불가능해지고 성분의 version을 그대로 읽어서 사용해야함
  // categoryType === 1 -> product.ingredients[0].verison === 'v1'
  const ingredientVersion = (product?.ingredients?.length > 0 && product?.ingredients[0]?.version) || 'v1';
  // v1 ewgGrade A, B, C, D, F, X
  // v2 ewgCode AA, AB, AC, BB, BC, BA, CC...
  const code = ingredientVersion === 'v1' ? props.ewgGrade : props.ewgCode;
  const gradeIcon = (ingredientVersion === 'v1' ? 'ewgGrade' : 'ewgCode') + code;
  const isAllergic = 'allergic' + props.allergic;
  const momguideGrade =
    props.momguideGrade !== null || props.momguideGrade !== undefined ? images[`momguideGrade${props.momguideGrade}`] : null;

  // 가정용 화학제품
  let img1 = ingredientVersion === 'v1' && props.isHarmness && images.isHarmness; // 알레르기 사람얼굴 아이콘 (R, S)
  let img2 = images[`echaCMR${props.CMR === 'C' || props.CMR === 'CCC' ? props.CMR : props.CMR === 'CC' ? props.CMR : ''}`]; // 캐나다.(true일때 보여준다.)
  let img3 = undefined; // 미국 (널이 아이구 Y일 경우 보여준다.)

  if (ingredientVersion !== 'v1') {
    img1 = images['echaCMR' + (props.echaCMR === 'P' ? 'P' : null)];
    img2 = images['echaCMR' + (props.echaCMR === 'C' || props.echaCMR === 'CCC' ? 'C' : props.echaCMR === 'CC' ? 'CC' : null)];
    img3 = undefined;
  }
  return (
    <div
      style={{ cursor: 'pointer' }}
      onClick={async () => {
        const logData = {
          제품명: props.product.name,
          productId: props.product?.id,
          성분명: props.korName,
        };
        goIngredientDetailPage('ingredientDetail', props, logData);
      }}
    >
      <div className={styles.listItem} style={{ borderBottomWidth: index === count - 1 ? 0 : 1 }}>
        <img style={{ width: 35, height: 35 }} src={images[gradeIcon]} />

        <div
          style={{
            margin: '0 12px',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <span className={styles.ingredientNameText}>{props.korName}</span>
          {!!props.displayedDiffName && (
            <span className={styles.productDetailText8} style={{ marginTop: 1 }}>
              {props.displayedDiffName}
            </span>
          )}
          <span className={styles.productDetailText8}>{props.engName}</span>
        </div>

        <div className={styles.cautionIngredient}>
          {img1 && (
            <div
              style={{
                width: 30,
                alignItems: 'flex-end',
                marginRight: 5,
              }}
            >
              <img
                style={{
                  width: 30,
                  height: 30,
                }}
                src={img1}
              />
            </div>
          )}
          {img2 && (
            <div
              style={{
                width: 30,
                alignItems: 'flex-end',
                marginRight: 5,
              }}
            >
              <img style={{ width: 30, height: 30 }} src={img2} />
            </div>
          )}
          {img3 && (
            <div
              style={{
                width: 30,
                alignItems: 'flex-end',
                marginRight: 5,
              }}
            >
              <img
                style={{
                  width: 30,
                  height: 30,
                }}
                src={img3}
              />
            </div>
          )}
          {momguideGrade && (
            <div
              style={{
                width: 30,
                alignItems: 'center',
                marginRight: 5,
              }}
            >
              <img
                style={{
                  width: 30,
                  height: 30,
                }}
                src={momguideGrade}
              />
            </div>
          )}
          <Plus size={10} borderColor={'#c4c4c4'} plusColor={'#c4c4c4'} />
        </div>
      </div>
    </div>
  );
};
/**
 * 가정용 화학제품
 * |등급(ewgGrade) -- 성분명(korName) -- 유해성분(isHarmness) -- 주의성분1(allergic) -- 주의성분2(isDSL) -- 주의성분3(EPA)
 *                                    해골아이콘              알레르기                  캐나다            미국
 * 유아용 화학제품
 * |-- 등급(ewgCode)--성분명(korName)--주의성분1(echaCMR) -- 주의성분2(echaCMR) -- 주의성분3(allergic)
 *                                  해외                  방독면              사람기침
 * @param param0
 *
 *
 */
interface Props {
  productData: IModel.Product;
  selectedGrade?: any;
  goIngredientDetailPage: any;
  headerVisible?: boolean;
  onShowIngredientRateGrades: any;
  path: string;
}
const IngredientList = ({
  productData,
  selectedGrade,
  goIngredientDetailPage,
  headerVisible = false,
  onShowIngredientRateGrades,
  path,
}: Props) => {
  const ingredietnsData = productData.ingredients;
  const ingredientVersion = (productData?.ingredients?.length > 0 && productData?.ingredients[0]?.version) || 'v1';
  // const code = productData.categoryType === 1 ? 'ewgGrade' : 'ewgGrade'
  const code = ingredientVersion == 'v1' ? 'ewgGrade' : 'ewgCode';
  const headerExplainText = ingredientExplainTextDatas.find((d) => d.ingredientVersion === ingredientVersion);
  const sortedIngs = Sort(
    code,
    ingredietnsData.filter((v: any, index: number) => {
      return v[code] == (selectedGrade == null ? v[code] : selectedGrade);
    }),
    ingredientVersion === 'v1' ? false : null,
  );
  return (
    <div style={{ width: '100%' }}>
      <>
        {headerVisible && (
          <div className={styles.headerExplainView}>
            {headerExplainText?.explainTexts.map(({ text, screen, screenId }) => {
              const textArray = text.split('\n');
              return (
                <div style={{ marginTop: 5, display: 'flex' }} key={text}>
                  <span className={styles.headerExplainText}>•</span>
                  <span className={styles.headerExplainText}>{textArray[0]}</span>
                </div>
              );
            })}
            <div>
              <div style={{ cursor: 'pointer' }} onClick={onShowIngredientRateGrades}>
                <span className={styles.headerExplainText}>•</span>
                <span className={styles.textUnderline}>EWG 등급 기준</span>
              </div>
              <div style={{ flex: 1.3 }} />
            </div>
          </div>
        )}
        <div className={styles.headerView}>
          <div style={{ flexDirection: 'row', display: 'flex', flex: 1 }}>
            <span className={styles.headerText}>EWG등급 | 성분명 </span>
          </div>

          <div className={styles.cautionIngredient} style={{ justifyContent: 'flex-end' }}>
            <div style={{ alignItems: 'flex-end' }}>
              <span className={styles.headerText} style={{ paddingRight: 40, padding: '5px 25px 5px 0' }}>
                주의성분
              </span>
            </div>
          </div>
        </div>
      </>
      {sortedIngs.map((ingredientItem, index) => {
        return (
          <IngredientListItem
            key={index}
            index={index}
            count={sortedIngs.length}
            product={productData}
            goIngredientDetailPage={goIngredientDetailPage}
            {...ingredientItem}
            path={path}
          />
        );
      })}
    </div>
  );
};

export default IngredientList;

// productData.categoryType === 1
//   ? '성분은 등급 순서대로 기재되어 있습니다'
//   : productData.categoryType === 2
//   ? '전성분은 함량 순서대로 기재되어 있습니다'
//   : productData.categoryType === 3
//   ? '해당 성분 정보는 함량 순이 아닙니다. \n덴탈케어 제품은 의약외품으로서 화장품의 성분 표기법을 따르지 않습니다. 따라서 제품에 표기된 성분과 아래 성분 표기가 다를 수 있으나, 동일한 성분입니다. 자세히 알아보기'
//   : '동물용의약외품 성분 정보는 함량 순이 아닐 수 있습니다.';
