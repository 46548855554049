import React from 'react';
import * as styles from '../../styles/productDetail/productDetailExplain.module.css';

const Dot = () => {
  return (
    <div
      style={{
        display: 'flex',
        marginTop: 3,
        width: 10,
      }}
    >
      <div
        style={{
          width: 5,
          height: 5,
          borderRadius: 5 / 2,
          backgroundColor: '#d8d8d8',
        }}
      />
    </div>
  );
};

const ProductDetailExplain = (props) => {
  const { productData } = props;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 11,
        backgroundColor: '#f3f3f3',
        padding: 22,
      }}
    >
      <div className={styles.explainContainer}>
        <Dot />
        <div style={{ flex: 1, display: 'flex' }}>
          <span className={styles.explainText}>
            {productData.categoryType === 1
              ? '생활화학제품 성분 정보는 제조/판매 업체에서 공개한 자료를 기반으로 제공되며, 함량 정보는 포함하지 않습니다.'
              : productData.categoryType === 2
                ? '화장품 전성분 정보는 제조/판매 업체에서 공개한 자료를 기반으로 제공되며, 함량순으로 기재됩니다.'
                : productData.categoryType === 3
                  ? '의약외품 전성분 정보는 제조/판매 업체에서 공개한 자료 및 식약처 공공데이터를 기반으로 제공되며, 함량 정보는 포함하지 않습니다.'
                  : productData.categoryType === 4
                    ? '펫케어 제품 성분 정보는 제조/판매 업체에서 공개한 자료를 기반으로 제공되며, 함량 정보는 포함하지 않습니다.'
                    : null}
          </span>
        </div>
      </div>
      <div className={styles.explainContainer}>
        <Dot />
        <div style={{ flex: 1, display: 'flex' }}>
          <span className={styles.explainText}>
            위 정보들은 제품 자체의 위해성을 의미하진 않으며, 특정 성분의 노출경로나 함량에 따라 유해 여부가 달라질 수 있으니 참고사항으로
            봐주시기 바랍니다.
          </span>
        </div>
      </div>
      <div className={styles.explainContainer}>
        <Dot />
        <div style={{ flex: 1, display: 'flex' }}>
          <span className={styles.explainText}>
            정보 업데이트 과정에서 크고 작은 오류가 발생할 수 있으며, 리뉴얼 여부가 실시간으로 반영되지 않으니 실제 제품 성분표를 반드시
            한번 더 확인해주세요.
          </span>
        </div>
      </div>
      <div className={styles.explainContainer}>
        <Dot />
        <div style={{ flex: 1, display: 'flex' }}>
          <span className={styles.explainText}>위 정보를 허가 없이 상업적으로 이용하는 경우, 법적조치를 받을 수 있습니다.</span>
        </div>
      </div>
    </div>
  );
};

export { ProductDetailExplain };
