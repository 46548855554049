import React from 'react';
import * as styles from '../styles/loading.module.css';

const LoadingComp = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        zIndex: 10,
      }}
    >
      <div className={styles.loader + ' ' + styles.loader6}></div>
    </div>
  );
};

export default LoadingComp;
