import { Link, navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';

import { apiProductDetailV3, apiGetClinicalTrials } from 'src/apis/productApi';
import AppContainerView from 'src/components/AppContainerView';
import MobileTemplate from 'src/components/MobileTemplate';
import AwardListItem from 'src/components/productDetail/AwardListItem';
import AwardInfoBox from 'src/components/productDetail/AwardBox';
import ProductInfo from 'src/components/productDetail/ProductInfo';
import RankingListItem from 'src/components/productDetail/RankingListItem';
import * as styles from 'src/styles/productDetail/productDetail.module.css';
import MobileHeader from 'src/components/MobileHeader';
import IngredientTemplate from 'src/components/productDetail/IngredientTemplate';
import Review from 'src/components/productDetail/Review';
import LoadingComp from 'src/components/Loading';
import ModalPopup from 'src/components/ModalPopup';
import AwardHistoryComp from 'src/components/productDetail/AwardExplain';
import WhiteBoxMenu from 'src/components/productDetail/WhiteBoxMenu';
import { amplitudeLog } from 'src/Lib/ampletude';
import { getPlatform } from 'src/utils';
import { useSelector } from 'react-redux';
import { useSiteMetadata } from 'src/hooks/useSiteMetadata';
import { SEO } from 'src/components/seo';

export default function ProductDetail({ data, params, location }: any) {
  const [productDetail, setProductDetail] = useState<any>();
  const [productName, setProductName] = useState<string>('');
  const [clinicalTrials, setClinicalTrials] = useState('');
  const [isAwardPopupVisible, setIsAwardPopupVisible] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const { kakaoApiKey } = useSiteMetadata();
  const productId = params.id;
  const appType = useSelector((state) => state.appType);

  useEffect(() => {
    loadProduct();
    getClinicalTrials();
    amplitudeLog('제품 상세페이지 total', {
      'path': location.state?.path,
      'type': getPlatform(),
      productId,
      '맘/펫': appType === 'mom' ? '맘가이드' : '펫가이드',
    });
  }, [productId]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://developers.kakao.com/sdk/js/kakao.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  });
  const getGradeName = (grade: string) => {
    if (grade === 'V') {
      return '맘가이드 클린';
    }
    if (grade === 'S') {
      return 'A등급';
    }
    if (grade === 'X') {
      return '안정성 불명확';
    }
    return grade + '등급';
  };

  const kakaoShare = () => {
    if (window.Kakao) {
      const kakao = window.Kakao;

      if (!kakao.isInitialized()) {
        kakao.init(kakaoApiKey);
      }

      kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: productDetail.name,
          description: '#' + productDetail.brand + ' #' + getGradeName(productDetail.ProductProperty.productGrade),
          imageUrl: productDetail.imgUrl, // i.e. process.env.FETCH_URL + '/logo.png'
          link: {
            androidExecutionParams: `productId=${productDetail.id}`,
            iosExecutionParams: `productId=${productDetail.id}`,
          },
        },
        buttonTitle: '유해성분 확인하기',
      });
    }
  };

  const loadProduct = async () => {
    const res = await apiProductDetailV3(productId);
    setProductDetail(res);
    setVisible(res?.categoryType === 1 || res?.categoryType === 4);

    setProductName(res.name.replace('dev-', ''));
    if (res.productBlind) {
      alert(res.productBlind.reason);
      navigate(-1);
    }
  };

  const getClinicalTrials = async () => {
    const clinicalTrialsData = await apiGetClinicalTrials(0, 30, productId);
    setClinicalTrials(clinicalTrialsData.rows);
  };

  const toggleAward = (index: number) => {
    setIsAwardPopupVisible(!isAwardPopupVisible);
  };

  const tabs = ['성분 정보', '리뷰'];

  const applyCommaToPrice = (price?: number | string) => {
    if (!price && price !== 0) return ''; // 값이 없을 때는 빈 문자열 반환
    return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || '0';
  };

  return (
    <AppContainerView pageTitle={`${productDetail?.brand} ${productName}`}>
      <MobileTemplate>
        <MobileHeader title="" onShare={kakaoShare} isHomeButton />
        {!productDetail ? (
          <LoadingComp />
        ) : (
          <>
            <ProductInfo product={productDetail} />
            <WhiteBoxMenu
              title="랭킹"
              productData={productDetail}
              RightComponent={() => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {productDetail.categories.map((data, index) => (
                    <RankingListItem
                      key={data.id}
                      data={data}
                      onPressCategory={(data) => {
                        if (data.child.child.child === undefined) {
                          navigate(
                            `/productResult/${data.id + ',' + data.child?.id}?subCategoryId=${data.child?.child?.id}`,
                          );
                        } else {
                          navigate(
                            `/productResult/${data.id + ',' + data.child?.id}?subCategoryId=${data.child?.child?.id}&subCategoryId2=${
                              data.child?.child?.child?.id
                            }`,
                          );
                        }
                      }}
                      index={index}
                    />
                  ))}
                </div>
              )}
            />
            <div>
              {productDetail.yearAndCleanAwardList?.length > 0 && (
                <AwardInfoBox
                  title="인증 및 수상"
                  data={productDetail.yearAndCleanAwardList || []}
                  ListRenderItem={(item) => {
                    const { data, index } = item;
                    return <AwardListItem data={data} index={index} toggleAward={toggleAward} />;
                  }}
                />
              )}
            </div>
            {/* clinicalTrials */}
            {clinicalTrials.length > 0 && (
              <div className={styles.clinicalTrialContainer}>
                <div
                  style={{
                    display: 'flex',
                    margin: '0px 22px',
                    borderRadius: 5,
                    border: '1px solid #dadada',
                    paddingTop: 14,
                  }}
                >
                  {isModalOpen && (
                    <div
                      style={{
                        display: 'flex',
                        position: 'absolute',
                        top: -350,
                        left: 0,
                        width: '100%',
                        height: '120%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1,
                      }}
                    >
                      <div
                        style={{
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: 286,
                          paddingTop: 28,
                          paddingBottom: 13,
                          backgroundColor: '#fff',
                          borderRadius: '10px',
                          textAlign: 'center',
                          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        }}
                      >
                        <span
                          style={{
                            fontSize: 14,
                            color: '#030303',
                          }}
                        >
                          상세한 평가보고서는
                          <br />
                          맘가이드 앱에서 확인할 수 있어요.
                        </span>
                        <div style={{ height: 1, backgroundColor: '#DADADA', marginTop: 22 }} />
                        <br />
                        <span
                          onClick={() => setIsModalOpen(false)}
                          style={{
                            margin: '12px 0px 0px 0px',
                            cursor: 'pointer',
                            color: '#007AFF',
                          }}
                        >
                          OK
                        </span>
                      </div>
                    </div>
                  )}

                  <div
                    style={{ position: 'absolute', right: 28, cursor: 'pointer' }}
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                  >
                    <img
                      style={{ position: 'relative', width: 14, height: 14, bottom: 9 }}
                      src="/images/infoGray.png"
                    />
                  </div>
                  <span style={{ color: '#424242', fontWeight: 600, fontSize: 14, marginRight: 24, marginLeft: 18 }}>
                    효능/효과
                    <br />
                    검토 완료
                  </span>
                  <div>
                    {clinicalTrials?.map((data, index) => {
                      return (
                        <div
                          key={data.id}
                          style={{
                            marginLeft: 12,
                            marginBottom: 10,
                            maxWidth: 180,
                          }}
                        >
                          <span className={styles.clinicalTrialItemText}>{data.name}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            <div className={styles.line} />
            <div
              style={{
                display: 'flex',
                backgroundColor: '#ffffff',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {tabs.map((tabData, index) => {
                return (
                  <div
                    key={tabData}
                    onClick={() => {
                      setTabIndex(index);
                    }}
                    style={{
                      display: 'flex',
                      width: '50%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      padding: '13px 0px',
                      borderStyle: 'solid',
                      borderBottomColor: tabIndex == index ? '#000000' : '#DADADA',
                      borderWidth: tabIndex == index ? '0 0 1.5px 0' : '0 0 0.5px 0',
                    }}
                  >
                    <span
                      style={{
                        fontSize: 14,
                        fontWeight: tabIndex == index ? '600' : '400',
                        color: tabIndex == index ? '#424242' : '#898989',
                      }}
                    >
                      {tabData}
                      {index == 1 && `(${applyCommaToPrice(productDetail?.scoreCnt)})`}
                    </span>
                  </div>
                );
              })}
            </div>
            {tabIndex === 0 && <IngredientTemplate product={productDetail} visible={visible} />}
            {tabIndex === 1 && <Review product={productDetail} />}
          </>
        )}
        <ModalPopup isVisible={isAwardPopupVisible} setIsVisible={setIsAwardPopupVisible} title="인증 및 수상내역">
          <AwardHistoryComp product={productDetail} />
        </ModalPopup>
      </MobileTemplate>
    </AppContainerView>
  );
}

export const Head = ({ location, params, data, pageContext }: any) => {
  const productId = params.id;
  const [product, setProduct] = useState<any>();
  const [productName, setProductName] = useState<string>('');
  const { siteUrl } = useSiteMetadata();

  const loadProduct = async () => {
    const res = await apiProductDetailV3(productId);
    setProduct(res);
    setProductName(res.name.replace('dev-', ''));
    if (res.productBlind) {
      alert(res.productBlind.reason);
      navigate(-1);
    }
  };

  useEffect(() => {
    loadProduct();
  }, [productId]);

  const title = productName;
  const pageUrl = `${siteUrl}${location.pathname}`;

  return (
    <SEO title={title} description={title} pageUrl={pageUrl}>
      <meta name="og:title" property="og:title" content={title} />
    </SEO>
  );
};
