import React from 'react';

const CancelBtn = (props) => {
  const { color = '#898989', onClick, width = 30, height = 30, style } = props;
  return (
    <svg
      style={style}
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        // fill-rule="evenodd"
        // clip-rule="evenodd"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3335 19.0278L11.0475 10.5952L19.3335 19.0278Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3335 10.5952L11.0475 19.0278L19.3335 10.5952Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CancelBtn;
