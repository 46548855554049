import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getProductNaverShopping } from '../../apis/productApi';
import { images } from '../../data';
import { RootState } from '../../state/rootStore';
import StarRate from '../StarRate';

const Review = (props) => {
  const { product } = props;
  const [naverReview, setNaverReview] = useState({});
  const appType = useSelector((state: RootState) => state.appType);

  useEffect(() => {
    const getNaverReview = async () => {
      const res = await getProductNaverShopping(product.id);
      // navigation.push('WebView', { title: '네이버 리뷰', uri: res.url })
      setNaverReview(res);
    };
    if (product.id) getNaverReview();
  }, [product]);

  return (
    <>
      <div
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <span
          style={{
            borderBottom: 'solid #c4c4c4 1px',
            margin: '0 8px',
            padding: '14px 9px',
            fontSize: 15,
            fontWeight: 600,
          }}
        >
          리뷰
        </span>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              alignItems: 'center',
              padding: '29px',
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span
              style={{
                fontSize: 12,
                fontWeight: 700,
                color: '#000000',
                padding: '0 5px',
                background: 'linear-gradient(to top, var(--lightMintOpacity) 75%, transparent 10%)',
              }}
            >
              {appType === 'mom' ? '맘가이드 평점' : '펫가이드 평점'}
            </span>

            <span
              style={{
                fontSize: 32,
                fontWeight: 700,
                marginTop: 8,
                marginRight: 10,
              }}
            >
              {product?.score}
            </span>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: 6,
              }}
            >
              <StarRate maxRating={5} rating={Number(product?.score)} size={17} gap={4} />

              <span
                style={{
                  color: '#000000',
                  fontSize: 10,
                  marginLeft: 1,
                  marginTop: 3,
                  fontWeight: 700,
                }}
              >
                ({product?.scoreCnt})
              </span>
            </div>
          </div>

          <div
            style={{
              alignItems: 'center',
              padding: '29px',
              flex: 1,
              position: 'relative',
              backgroundColor: '#fbfbfb',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {/* <div
              style={{ position: "absolute", top: 15, right: 15 }}
              onClick={() => {
                // navigation.push('FAQDetail', { tab: 3, id: 1455 });
              }}
            >
              <img
                style={{ width: 20, height: 20 }}
                src={images.questionIcon}
              />
            </div> */}

            <span
              style={{
                fontSize: 12,
                fontWeight: 700,
                color: '#000000',
                padding: '0 5px',
                background: 'linear-gradient(to top, #a8d5ff50 75%, transparent 10%)',
              }}
            >
              쇼핑몰 평점
            </span>

            <div style={{ display: 'flex', margin: '13Px' }}>
              <span style={{ fontSize: 18, marginRight: 10 }}>{naverReview?.score || 0}</span>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <StarRate maxRating={1} rating={Number(product?.score)} size={13} gap={4} />

                <span
                  style={{
                    color: '#898989',
                    fontSize: 10,
                    marginLeft: 1,
                    marginTop: 3,
                    fontWeight: 700,
                  }}
                >
                  ({naverReview?.count || 0})
                </span>
              </div>
            </div>
            {naverReview?.url ? (
              <div
                style={{
                  borderRadius: 7,
                  border: 'solid #dadada 1px',
                  backgroundColor: '#f3f3f3',
                  paddingBottom: 2,
                }}
                onClick={() => {
                  //   navigation.push('WebView', {
                  //     title: '쇼핑몰 리뷰',
                  //     uri: naverReview?.url || 'https://msearch.shopping.naver.com/catalog',
                  //   });
                }}
              >
                <a
                  style={{
                    padding: '5px 11px',
                    fontSize: 12,
                    margin: 0,
                    border: 0,
                    verticalAlign: 'baseline',
                  }}
                  href={naverReview?.url || 'https://msearch.shopping.naver.com/catalog'}
                  target="_blank"
                >
                  쇼핑몰 리뷰 보러 가기
                </a>
              </div>
            ) : (
              <span
                style={{
                  color: '#898989',
                  fontSize: 12,
                  textAlign: 'center',
                  display: 'flex',
                  position: 'relative',
                  whiteSpace: 'pre-line',
                }}
              >
                {'쇼핑몰 리뷰를\n가져올 수 없는 제품이에요.'}
              </span>
            )}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            position: 'relative',
            justifyContent: 'center',
          }}
        >
          <span
            style={{
              position: 'absolute',
              top: '20%',
              whiteSpace: 'pre-line',
              textAlign: 'center',
              fontWeight: 700,
              fontSize: 14,
            }}
          >
            깐깐한 맘가이드 회원의 <span style={{ color: 'var(--vividMint)' }}>리얼 리뷰</span>는{'\n'}
            맘가이드 앱에서 확인해주세요!
          </span>
          <img style={{ width: '100%' }} src={images.reviewBlurImage} />
        </div>
      </div>
    </>
  );
};

export default Review;
