import React from 'react';
import { images } from '../data';
import CancelBtn from './CantelBtn';
import { useIsMobile } from './mediaQuery';

const ModalHeader = (props) => {
  const {
    title,
    TitleComponent,
    backgroundColor = '#ffffff',
    style,
    titleStyle,
    cancelColor = '#898989',
    onCancel,
  } = props;
  const isMobile = useIsMobile();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        width: '100%',
        position: 'sticky',
        height: 60,
        alignItems: 'center',
        top: isMobile ? 0 : 90,
        zIndex: 1,
        background: backgroundColor,
        ...style,
      }}
    >
      <div>
        {TitleComponent ? (
          TitleComponent
        ) : (
          <span
            style={{
              fontSize: 14,
              fontWeight: 700,
              color: '#000000',
              ...titleStyle,
            }}
          >
            {title}
          </span>
        )}
      </div>

      <CancelBtn
        style={{ position: 'absolute', right: 20, cursor: 'pointer' }}
        width={30}
        height={30}
        onClick={onCancel}
        color={cancelColor}
      />
    </div>
  );
};
export default ModalHeader;
