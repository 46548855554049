import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as styles from '../../styles/productDetail/ingredient.module.css';
import { RootState, showPopup } from '../../state/rootStore';
import IngredientList from './IngredientList';
import { IngredientCautionTemplate } from './IngredientCautionTemplate';
import { ProductDetailExplain } from './ProductDetailExplain';
import { Link, navigate } from 'gatsby';
import ModalPopup from '../ModalPopup';
import { getImageModal } from '@/apis/commom';
import { ingredientOpenGradeStatus } from '@/data';
import useMomguideProductGrades from '@/hooks/useMomguideGrade';

const IngredientTemplate = (props) => {
  const appType = useSelector((state: RootState) => state.appType);
  const [modalData, setModalData] = useState();
  const { product, visible } = props;
  const [allSelectedIngredient, setAllSelectedIngredinet] = useState<boolean>();
  const [isMomguideDetailInfo, setIsMomguideDetailInfo] = useState<boolean>(false);
  const dispatch = useDispatch();
  const momguideProductGrades = useMomguideProductGrades();
  const grade = momguideProductGrades.find((item) => item.grade === product?.ProductProperty?.productGrade);
  const gradeImage = grade?.image2Big;
  const gradeColor = grade?.color;
  const gradeTitle = grade?.title;
  const gradeExplain = grade?.explain2;
  const authExplain = `${appType === 'mom' ? '맘가이드' : '펫가이드'}가 제품의 시험성적서, 소명자료 등을 직접 검토하여 ${
    appType === 'mom' ? '맘가이드' : '펫가이드'
  }의 엄격한 기준을 충족한 제품`;

  const ingredientGrade = product.ProductProperty?.ingredientGrade;
  // const ingredient = product.categoryType === 1 ? product.ingredientV1 : product.ingredientV2
  const ingredients = product.ingredients;
  const isCertified = product?.ProductProperty?.isCertified;

  const getModalData = async (displayedAt: 'ingredient' | 'grade') => {
    const res = await getImageModal(displayedAt);
    const otherData = {};
    if (displayedAt === 'ingredient') {
      otherData.title = '맘가이드만의 기준';
      otherData.titleColor = '#ffffff';
      otherData.titleBackgroundColor = '#4CDDCB';
    } else {
      otherData.title = appType === 'pet' ? '펫가이드 등급' : '맘가이드 등급';
      otherData.titleColor = '#000000';
      otherData.titleBackgroundColor = '#ffffff';
    }
    setModalData({ ...otherData, images: res.rows });
  };

  const toggleSetAllSelectedIngredinet = () => {
    // setSelectedGrade(null);
    setAllSelectedIngredinet(!allSelectedIngredient);

    // amplitudeLog('제품 상세 - 전체성분 펼치기', { 제품명: productData.id, productId: productData.id });
  };

  const goIngredientDetailPage = (where: string, ingredient?: any, logData?: any) => {
    const versrion = (ingredient.product?.categoryType ?? ingredient.categoryType) === 1 ? 'v1' : 'v2';
    //   amplitudeLog('제품 상세 - 전체성분 펼치기 - 성분클릭', logData);
    //   goIngredientDetailPage(where, ingredient);

    navigate(`/ingredientDetail/${ingredient.id}?version=${versrion}`, {
      state: {
        ingredient: JSON.stringify(ingredient),
      },
    });
  };

  const onShowInterestIngredient = (type: 'baby' | 'usage') => {
    navigate(`/interestIngredient?type=${type}`);
  };
  // console.log(imageIngredientGrade[ingredientGrade]);
  return (
    <div className={styles.container}>
      {/* <div className={styles.title}>
        <span className={styles.boldText}>성분</span>
      </div> */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 18,
          marginLeft: 22,
        }}
      >
        <span
          style={{
            fontSize: 18,
            fontWeight: '600',
            marginRight: 4,
            color: '#000',
          }}
        >
          성분
        </span>
        {visible ? (
          <div
            // onPress={() => navigation.navigate('TipsDetail', { id: 1813 })}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '3px 6px',
              borderStyle: 'solid',
              borderWidth: 1,
              borderRadius: 20,
              top: 1,
              borderColor: '#DADADA',
            }}
          >
            <img
              style={{ width: 16, height: 16, marginRight: 4 }}
              src={ingredientOpenGradeStatus[ingredientGrade]?.icon}
            />
            <span style={{ fontSize: 12, color: '#898989', fontWeight: '500' }}>
              {ingredientGrade == 'O' ? '공개됨' : ingredientGrade == 'X' ? '비공개' : '부분공개'}
            </span>
            <img style={{ width: 16, height: 16, marginLeft: 3 }} src="/images/infoGray.png" />
          </div>
        ) : (
          false
        )}
      </div>

      <div
        className={styles.gradeExplainContainer}
        style={
          isCertified
            ? { margin: '18px 8px 0px 0px' }
            : {
                margin: '18px 8px',
                borderBottom: 'solid 1px #c4c4c4',
                paddingBottom: '20px',
              }
        }
      >
        <div
          style={{
            position: 'relative',
          }}
        >
          {isCertified ? (
            <img
              style={{
                position: 'relative',
                width: '75px',
                height: 'auto',
              }}
              src={'/images/main/grade/cleanGradeA2x.png'}
            />
          ) : (
            <img
              style={{
                position: 'relative',
                width: '60px',
                height: 'auto',
              }}
              src={gradeImage}
            />
          )}
        </div>

        <div className={styles.gradeExplainTextBox}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                alignSelf: 'flex-start',
                padding: '3px 9px',
                border: `solid 1.2px ${isCertified ? '#00B8E0' : gradeColor}`,
                borderRadius: 118,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <span
                style={{
                  fontSize: 11,
                  color: isCertified ? '#00B8E0' : gradeColor,
                  fontWeight: 700,
                  margin: 0,
                  padding: 0,
                }}
              >
                {gradeTitle}
              </span>
            </div>
          </div>
          <span className={styles.explainText}>{gradeExplain}</span>
        </div>
      </div>

      {isCertified && (
        <div className={styles.authBox}>
          <div style={{ width: '29%' }}></div>

          <div className={styles.gradeExplainTextBox}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  padding: '1px 9px',
                  border: '1.2px solid #0ad3bd',
                  borderRadius: '15px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    fontSize: 11,
                    color: '#0ad3bd',
                    fontWeight: '700',
                  }}
                >
                  {appType === 'mom' ? '맘가이드 클린 제품' : '펫가이드 클린 제품'}
                </div>
                <div
                  style={{
                    width: 6.5,
                    height: 11,
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#0ad3bd',
                    border: '1px solid #0ad3bd',
                    borderRadius: 6,
                    paddingLeft: 4.5,
                    marginLeft: 4,
                  }}
                >
                  <div style={{ fontSize: 8, color: '#ffffff' }}>i</div>
                </div>
              </div>
            </div>
            <span className={styles.explainText}>{authExplain}</span>
          </div>
          <div style={{ width: '10%' }}></div>
        </div>
      )}

      <div>
        <div className={styles.expalinButtonContainer}>
          <div
            className={styles.button}
            onClick={() => {
              // amplitudeLog('제품상세 - 성분 판단 기준');
              // navigation.push('MomStandard');
              // navigate("/momStandard")
              setIsMomguideDetailInfo(true);
              getModalData('ingredient');
            }}
          >
            <img style={{ width: 14, height: 8, marginRight: 3 }} src={'/images/main/ingredientMIcon.png'} />

            <span className={styles.buttonText}>성분 판단 기준</span>

            <img className={styles.arrow} src="/images/arrow_left.svg" />
          </div>
          <div
            className={styles.button}
            style={{
              marginLeft: 14,
            }}
            onClick={() => {
              // amplitudeLog('제품상세 - 등급 부여 기준');
              // navigation.push('ProductGrade');
              // navigate("/productGradeExplain")
              setIsMomguideDetailInfo(true);
              getModalData('grade');
            }}
          >
            <img style={{ width: 10, height: 10, marginRight: 3 }} src={'/images/main/gradeCheckIcon.png'} />
            <span className={styles.buttonText}>등급 부여 기준</span>

            <img className={styles.arrow} src="/images/arrow_left.svg" />
          </div>
        </div>
        {ingredientGrade == '△' && (
          <div
            className={styles.ingredientRequestButton}
            onClick={() => {
              dispatch(
                showPopup({
                  text: `성분 공개 요청은 맘가이드 앱에서 이용해주세요.
                      회원님들의 요청을 취합하여 맘가이드가 업체에게 직접 성분 공개를 요청합니다.`,
                  popupLastPath: '성분 공개 요청',
                }),
              );
            }}
          >
            <span className={styles.ingredientRequestButtonText}>전체 성분 공개 요청</span>
          </div>
        )}

        {ingredients?.length > 0 ? (
          <div
            onClick={toggleSetAllSelectedIngredinet}
            style={{
              display: 'flex',
              backgroundColor: 'var(--whiteMint)',
              padding: '15px 21px 15px 18px',
              margin: 0,
              cursor: 'pointer',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <span style={{ fontSize: 12, fontWeight: 700, color: '#424242' }}>
                {product?.ProductProperty?.productGrade === 'X' ? '공개' : '전체'}
                성분 ({ingredients.length}개)
              </span>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span
                  style={{
                    color: '#c4c4c4',
                    fontSize: 12,
                    justifyContent: 'center',
                  }}
                >
                  펼쳐보기
                </span>

                <img
                  style={{
                    width: 24,
                    height: 12,
                    alignSelf: 'center',
                    transform: `rotate(${allSelectedIngredient ? '90deg' : '270deg'})`,
                  }}
                  src="/images/arrow_left.svg"
                />
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              backgroundColor: '#f0f9f7',
              padding: '15px 21px 15px 18px',
              display: 'flex',
              margin: 0,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <span style={{ fontSize: 12, fontWeight: 700, color: '#898989' }}>
                {product?.ProductProperty?.productGrade === 'X' ? '공개' : '전체'}
                성분 (0개)
              </span>
              <img
                style={{
                  width: 24,
                  height: 12,
                  transform: `rotate(${allSelectedIngredient ? '90deg' : '270deg'})`,
                }}
                src="/images/arrow_left.svg"
              />
            </div>
          </div>
        )}
      </div>

      <div
        className={
          allSelectedIngredient === undefined
            ? styles.listContainerFitst
            : allSelectedIngredient
              ? styles.listContainerShow
              : styles.listContainer
        }
        style={{
          borderBottom: `solid ${allSelectedIngredient ? 3 : 0}px #f3f3f3`,
        }}
      >
        <IngredientList
          productData={product}
          goIngredientDetailPage={(where: string, ingredient?: any, logData?: any) => {
            const versrion = ingredient.product.categoryType === 1 ? 'v1' : 'v2';
            //   amplitudeLog('제품 상세 - 전체성분 펼치기 - 성분클릭', logData);
            //   goIngredientDetailPage(where, ingredient);

            navigate(`/ingredientDetail/${ingredient.id}?version=${versrion}`, {
              state: {
                ingredient: JSON.stringify(ingredient),
              },
            });
          }}
          headerVisible={true}
          path="전체성분"
          onShowIngredientRateGrades={() => {
            //  navigation.push('IngredientRateGrades', {
            //    productData: ingredient.product || ingredient,
            //  });
            navigate(`/ewgGrade?version=${product.categoryType === 1 ? 'v1' : 'v2'}`);
          }}
        />
      </div>

      <IngredientCautionTemplate
        productData={product}
        goIngredientDetailPage={goIngredientDetailPage}
        onShowInterestIngredient={onShowInterestIngredient}
      />
      <ProductDetailExplain productData={product} />

      <ModalPopup
        isVisible={isMomguideDetailInfo}
        setIsVisible={setIsMomguideDetailInfo}
        title={modalData?.title}
        titleStyle={{ color: modalData?.titleColor }}
        backgroundColor={modalData?.titleBackgroundColor}
      >
        <div style={{ background: '#ffffff' }}>
          {modalData?.images?.map((item) => {
            return (
              <img
                style={{
                  width: '100%',
                  imageRendering: '-webkit-optimize-contrast',
                }}
                src={item.url}
              />
            );
          })}
        </div>
      </ModalPopup>
    </div>
  );
};
export default IngredientTemplate;
