import { useSelector } from 'react-redux';
import { momguideProductGrades, petguideProductGrades } from '../data';
import { RootState } from '../state/rootStore';

const useMomguideProductGrades = () => {
  const appType = useSelector((state: RootState) => state.appType);
  if (appType === 'pet') {
    return petguideProductGrades.sort((a, b) => a.order - b.order);
  }

  return momguideProductGrades.sort((a, b) => a.order - b.order);
};

export default useMomguideProductGrades;
