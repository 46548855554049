import React from 'react';

const RankingListItem = (props) => {
  const { data, onPressCategory, index } = props;

  return (
    <div
      style={{
        justifyContent: 'flex-start',
        display: 'flex',
        justifyItems: 'center',
        flexDirection: 'column',
        marginTop: index ? 10 : 0,
      }}
    >
      {data.id === 6 ? (
        <img style={{ width: 19, height: 19, marginRight: 1, marginTop: -3 }} src={'/images/PCselected.png'} />
      ) : null}
      <div style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }} onClick={() => onPressCategory(data)}>
        <span
          style={{
            color: '#424242',
            fontSize: 14,
            textDecorationLine: 'underline',
            textDecorationColor: '#898989',
          }}
        >
          {data.child?.child?.name} {data.child?.child?.child?.name ? ' > ' + data.child?.child?.child?.name : ''}{' '}
          {(data.child?.child?.child ? data.child?.child?.child?.rank : data.child?.child?.rank) || '-'}위
        </span>
      </div>
    </div>
  );
};

export default RankingListItem;
