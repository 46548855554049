import React, { Component } from 'react';
import { images } from '../../data';

interface Props {
  title: string;
  productData: IModel.ProductDetail;
  // onPressCategory: (category: IModel.Category) => void;
  RightComponent: React.FC;
  style?: StyleProp<ViewStyle>;
}

const WhiteBoxMenu = (props: Props) => {
  const { style, title, RightComponent } = props;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: 5,
        backgroundColor: '#ffffff',
        marginLeft: 22,
        marginTop: 15,
        marginBottom: 10,
      }}
    >
      <span style={{ color: '#898989', fontWeight: 600, fontSize: 14, marginRight: 71 }}>{title}</span>
      <RightComponent />
    </div>
  );
};

export default WhiteBoxMenu;
