import React from 'react';

interface GradeBoxProps {
  title: string;
  data: any[];
  ListRenderItem: React.FC<{ data: any; index?: number }>;
  FooterItem?: React.FC<any>;
}

const AwardInfoBox = (props: GradeBoxProps) => {
  const { title, data, ListRenderItem, FooterItem } = props;
  const awards = data;

  return (
    <div
      style={{
        marginBottom: 10,
        width: '100%',
        justifyContent: 'center',
        backgroundColor: '#ffffff',
        borderWidth: 1,
        borderColor: '#dadada',
      }}
    >
      <div
        style={{
          display: 'flex',
          margin: '0px 22px',
          borderRadius: 5,
          border: '1px solid #dadada',
          paddingTop: 14,
        }}
      >
        <span style={{ color: '#424242', fontSize: 14, fontWeight: 600, marginLeft: 18 }}>{title}</span>
        <div
          style={{
            display: 'flex',
            marginLeft: 12,
          }}
        >
          <div>
            {awards.map((d, index) => {
              return <ListRenderItem data={d} index={index} key={index.toString()} />;
            })}
          </div>
          {FooterItem && <FooterItem />}
        </div>
      </div>
    </div>
  );
};

export default AwardInfoBox;
